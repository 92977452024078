import { ActionFunctionArgs, redirect } from "@remix-run/node";
import { Form } from "@remix-run/react";
import { InputFieldWithLabel } from "~/components/InputField";
import { Button } from "~/components/Button";

export const action = async ({ request }: ActionFunctionArgs) => {
  const formData = await request.formData();
  const password = formData.get("password");
  if (password === "prince") {
    return redirect("/", {
      headers: { "Set-Cookie": `toby=leinuro; HttpOnly; Path=/` },
    });
  } else {
    return redirect("/work-in-progress");
  }
};

export default function WorkInProgressRoute() {
  return (
    <div className="flex h-full w-full flex-col items-center justify-center gap-y-4">
      <div>
        <h1 className="text-3xl font-bold">ServerJet - Work in progress</h1>
        <Form method="POST" className="space-y-2">
          <InputFieldWithLabel type="password" name="password" label="Password" />
          <Button size="MD" theme="primary" type="submit" text="Log in" />
        </Form>
      </div>
    </div>
  );
}
